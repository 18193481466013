export const faqData = [
  {
    id: 1,
    question: "What is Maruti Suzuki Arena?",
    answer:
      "Maruti Suzuki Arena sports a modern look, offering a customer-friendly environment. Maruti has embraced digital technology for streamlining the process of purchasing or customising cars. With this, the company now has four distinct retail channels -Maruti Suzuki ARENA, NEXA, Commercial and True Value.",
  },
  {
    id: 2,
    question: "What are difference between Maruti Suzuki Arena & Nexa?",
    answer:
      "Maruti suzuki is a parent company and indian market leader too whereas NEXA is just a big dealer had a hand with suzuki. Nexa is not an company but a premium car dealer of maruti Suzuki and they updated the cars with more features and safeties. services also little bit differs.",
  },
  {
    id: 3,
    question: "How can we book a Maruti New Car? ( Online/ Direct Walkin)",
    answer:
      "Register your details to SABOO RKS Pvt. Ltd. Maruti Suzuki Dealer https://www.saboomaruti.in confirm your details.  Or you May call us on 9848898488 Maruti Car Enquire enquiry https://www.saboomaruti.in/contact-maruti-suzuki-showroom Maruti Car Service:  https://www.saboomaruti.in/maruti-car-service-centers-near-me Maruti Car Complaints/ Queries/ Feedback:  https://www.saboomaruti.in/contact-maruti-suzuki-showroom Maruti Car Offers:  https://www.saboomaruti.in/maruti-suzuki-car-offers-page.",
  },
  {
    id: 4,
    question: "Is Test drive can be scheduled? (Yes/ No)?",
    answer:
      "Yes, Book a test drive now: https://www.saboomaruti.in/  (or)  call us on 9848898488.",
  },
  {
    id: 5,
    question: "How to get a loan as a first-time car buyer?",
    answer:
      "Maruti will enhance your car buying experience with Maruti Finance.",
  },
];
