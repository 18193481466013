const dzireSliders = [
  {
    original:
      "https://marutisuzukiarenaprodcdn.azureedge.net/-/media/images/maruti/marutisuzuki/car/car-detail/dzire-launch-2024/whats-new-section/dzire_electric_sunroof.webp",
    thumbnail:
      "https://marutisuzukiarenaprodcdn.azureedge.net/-/media/images/maruti/marutisuzuki/car/car-detail/dzire-launch-2024/whats-new-section/dzire_electric_sunroof.webp",
  },
  {
    original:
      "https://marutisuzukiarenaprodcdn.azureedge.net/-/media/images/maruti/marutisuzuki/car/car-detail/dzire-launch-2024/whats-new-section/front-grill-shot.webp",
    thumbnail:
      "https://marutisuzukiarenaprodcdn.azureedge.net/-/media/images/maruti/marutisuzuki/car/car-detail/dzire-launch-2024/whats-new-section/front-grill-shot.webp",
  },
  {
    original:
      "https://marutisuzukiarenaprodcdn.azureedge.net/-/media/images/maruti/marutisuzuki/car/car-detail/dzire-launch-2024/whats-new-section/6_airbags.webp",
    thumbnail:
      "https://marutisuzukiarenaprodcdn.azureedge.net/-/media/images/maruti/marutisuzuki/car/car-detail/dzire-launch-2024/whats-new-section/6_airbags.webp",
  },
  {
    original:
      "https://marutisuzukiarenaprodcdn.azureedge.net/-/media/images/maruti/marutisuzuki/car/car-detail/dzire-launch-2024/whats-new-section/360-deg-camera.webp",
    thumbnail:
      "https://marutisuzukiarenaprodcdn.azureedge.net/-/media/images/maruti/marutisuzuki/car/car-detail/dzire-launch-2024/whats-new-section/360-deg-camera.webp",
  },
  {
    original:
      "https://marutisuzukiarenaprodcdn.azureedge.net/-/media/images/maruti/marutisuzuki/car/car-detail/dzire-launch-2024/whats-new-section/amt_gear.webp",

    thumbnail:
      "https://marutisuzukiarenaprodcdn.azureedge.net/-/media/images/maruti/marutisuzuki/car/car-detail/dzire-launch-2024/whats-new-section/amt_gear.webp",
  },
  {
    thumbnail:
      "https://marutisuzukiarenaprodcdn.azureedge.net/-/media/images/maruti/marutisuzuki/car/car-detail/dzire-launch-2024/whats-new-section/wireless_charger.webp",

    original:
      "https://marutisuzukiarenaprodcdn.azureedge.net/-/media/images/maruti/marutisuzuki/car/car-detail/dzire-launch-2024/whats-new-section/wireless_charger.webp",
  },
  // {
  //   original:
  //     "https://marutisuzukiarenaprodcdn.azureedge.net/-/media/images/maruti/marutisuzuki/car/car-detail/dzire-launch-2024/feature-videos-section/headlamps_drl.webp",

  //   thumbnail:
  //     "https://marutisuzukiarenaprodcdn.azureedge.net/-/media/images/maruti/marutisuzuki/car/car-detail/dzire-launch-2024/feature-videos-section/headlamps_drl.webp",
  // },
  // {
  //   thumbnail:
  //     "https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/Arena/sliders/maruti-dzire/Maruti-Suzuki-Dzire-Instrument-Cluster.webp",

  //   original:
  //     "https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/Arena/sliders/maruti-dzire/Maruti-Suzuki-Dzire-Instrument-Cluster.webp",
  // },
  // {
  //   original:
  //     "https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/Arena/sliders/maruti-dzire/Maruti-Suzuki-Dzire-Steering-Controls.webp",

  //   thumbnail:
  //     "https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/Arena/sliders/maruti-dzire/Maruti-Suzuki-Dzire-Steering-Controls.webp",
  // },
  // {
  //   original:
  //     "https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/Arena/sliders/maruti-dzire/Maruti-Suzuki-Dzire-Steering-Wheel.webp",
  //   thumbnail:
  //     "https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/Arena/sliders/maruti-dzire/Maruti-Suzuki-Dzire-Steering-Wheel.webp",
  // },
  // {
  //   original:
  //     "https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/Arena/sliders/maruti-dzire/Maruti-Suzuki-Dzire-Upholstery-Details.webp",

  //   thumbnail:
  //     "https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/Arena/sliders/maruti-dzire/Maruti-Suzuki-Dzire-Upholstery-Details.webp",
  // },
];

const dzireColors = [
  {
    id: 1,
    img: "https://www.marutisuzuki.com/images/dzire_brand/images/threesixty/red.webp",
  },
  {
    id: 2,
    img: "https://www.marutisuzuki.com/images/dzire_brand/images/threesixty/red/SplendidSilver.webp",
  },
  {
    id: 3,
    img: "https://www.marutisuzuki.com/images/dzire_brand/images/threesixty/red/MagmaGrey.webp",
  },
  {
    id: 4,
    img: "https://www.marutisuzuki.com/images/dzire_brand/images/threesixty/red/BluishBlack.webp",
  },
  {
    id: 5,
    img: "https://www.marutisuzuki.com/images/dzire_brand/images/threesixty/red/NutmegBrown.webp",
  },
  {
    id: 6,
    img: "https://www.marutisuzuki.com/images/dzire_brand/images/threesixty/red/AlluringBlue.webp",
  },
  // {
  //   id: 7,
  //   img: "https://www.marutisuzuki.com/images/dzire_brand/images/threesixty/blue/suzuki_swift_ext_360_oragne_v-1_6.webp",
  // },
];

const dzireVariants = [
  {
    id: 1,
    title: 'lxi',
    transmission: 'manual',

    price: '₹ 6,79,000  Lakh',
  },
  {
    id: 2,
    title: 'vxi ',
    transmission: 'manual',

    price: '₹ 7,79,000 Lakh',
  },
  {
    id: 3,
    title: 'zxi ',
    transmission: 'manual',

    price: '₹ 8,89,000 Lakh',
  },
  {
    id: 4,
    title: 'zxi+ ',
    transmission: 'manual',

    price: '₹ 9,69,000 Lakh',
  },
  {
    id: 5,
    title: 'vxi ags ',
    transmission: 'automatic',

    price: '₹ 8,24,000 Lakh',
  },
  {
    id: 6,
    title: 'zxi ags ',
    transmission: 'automatic',

    price: '₹ 9,34,000 Lakh',
  },
  {
    id: 7,
    title: 'zxi+ ags',
    transmission: 'automatic',

    price: '₹ 10,14,000 Lakh',
  },
  // {
  //   id: 8,
  //   title: 'vxi CNG 1.2l',
  //   transmission: 'manual',

  //   price: '₹ 8,44,250 Lakh',
  // },
  // {
  //   id: 9,
  //   title: 'zxi CNG 1.2l',
  //   transmission: 'manual',

  //   price: '₹ 9,12,250 Lakh',
  // },
  // {
  //   id: 10,
  //   title: 'tour s std (o)',
  //   transmission: 'manual',

  //   price: '₹ 6,56,000 Lakh',
  // },
  // {
  //   id: 11,
  //   title: 'tour s std (o) - cng',
  //   transmission: 'manual',

  //   price: '₹ 7,51,000 Lakh',
  // },
  // {
  //   id: 12,
  //   title: '',
  //   transmission: '',

  //   price: '',
  // },
];

const dzireMileage = [
  {
    id: 1,
    fueltype: 'petrol',
    transmission: 'manual',
    mileage: '24.79 KM/L',
  },
  {
    id: 2,
    fueltype: 'petrol ',
    transmission: 'automatic',
    mileage: '25.71 KM/L',
  },
  {
    id: 3,
    fueltype: 'cng ',
    transmission: '-',
    mileage: '33.73 KM/KG',
  },
];

export { dzireSliders, dzireColors, dzireVariants, dzireMileage };
